import React from "react"
import Layout from "Containers/Layout/Layout"

const GreenScreenPage = () => {

  return (
    <div 
      style={{
        width: "100vw",
        height: "100vh",
        background: "rgba(0,255,0,1)",
      }}
      className="greenscreen"
    >
      <Layout />
    </div>
  )
}

export default GreenScreenPage
